import React, { Component } from 'react';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from "react-redux";
import { DIR_RTL } from 'constants/ThemeConstant';
import confirm from 'antd/lib/modal/confirm';

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item key="logout" onClick={() => {
            confirm({
              title: 'Deseja sair da aplicação?',
              onOk() {
                localStorage.clear()
                window.location.reload()
              }
            })
          }}>
            <span><LogoutOutlined className="nav-icon mr-0" /></span>
          </Menu.Item>
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <span><SettingOutlined className="nav-icon mr-0" /></span>
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);